import { Dialog, IconButton } from '@material-ui/core'
import { actions, useDispatch, useSelector } from '@/redux'
import { makeStyles } from '@material-ui/core/styles'
import React from 'react'

import SplashAdImage from './SplashAdImage'

import CancelIcon from '@material-ui/icons/Cancel'

/**
 *
 * 進餐廳列表後顯示的蓋版廣告
 * 點擊 Splash Ad，可進入參加中秋盆菜的餐廳列表。
 * 在可預購期間，Splash Ad皆會顯示。
 * 圖片 url 透過 lading api (google sheet)/splash/欄位A取
 * @param {*} props
 * @returns
 */
export default function SplashAdDialog (props) {
  const classes = useStyles(props)
  const dispatch = useDispatch()
  const splashAd = useSelector(state => state.app.dialogs.splashAd)
  const open = (splashAd.open || splashAd.data?.open) ?? false

  const handleClose = () => {
    dispatch(actions.app.toggleDialog('splashAd', false, {}))
  }

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      className={classes.splashDialog}
    >
      <IconButton className={classes.closeButton} onClick={handleClose}>
        <CancelIcon className={classes.cancelIcon} />
      </IconButton>
      <SplashAdImage onClose={handleClose} />
    </Dialog>
  )
}

const useStyles = makeStyles(theme => ({
  splashDialog: {
    position: 'relative',
  },
  closeButton: {
    position: 'absolute',
    top: 0,
    right: 0,
    padding: theme.spacing(0.5),
  },
  cancelIcon: {
    color: theme.palette.common.white,
    backdropFilter: 'blur(4px)',
    borderRadius: '50%',
  },
}))
